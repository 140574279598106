$(document).ready(function() {
    $('form').parsley();
    
    $('#bank_account').parsley().on('field:error', function() {
        $('#bank_account').addClass('errorinput');
    });
    
    $('#bank_account').parsley().on('field:success', function() {
        $('#bank_account').removeClass('errorinput');
    });
    
    $('#email').parsley().on('field:error', function() {
        $('#email').addClass('errorinput');
    });
    
    $('#email').parsley().on('field:success', function() {
        $('#email').removeClass('errorinput');
    });
});